import { useState, useEffect, useLayoutEffect, createContext } from "react";
import T from "../common/traducao";
import { Formik } from "formik";
import { toast } from "react-toastify";
import NotaService from "../services/service.nota";
import AssistenciasService from "../services/service.assistencias";
import { useNavigate, useParams } from "react-router-dom";
import serviceAnexos from "../services/service.anexos";
import { GetConfiguracoes, GetResponsaveis } from "../config/localstorageConfig/localstorage";
import * as Yup from "yup";

const configGeral = GetConfiguracoes();
const configPermissoesAssistencia = configGeral?.ConfiguracaoAssistencia;
const configuracaoAnexosAssistencia = configGeral?.ConfiguracaoAnexosAssistencia;

const validationSchema = Yup.object();

const validationSchemaGravar = Yup.object().shape({
  Anexos: Yup.array().when([], (Assistencia, schema) => {
    if (configPermissoesAssistencia.ObrigarAnexosInclusao) {
      return schema.min(1, "É necessário incluir pelo menos um anexo");
    }
    return schema;
  }),
  ValidacaoQuantidade: Yup.string().when(["QtdeProblema", "ProdutoProblema", "ProdutoVendido", "ItemPedido"], {
    is: (QtdeProblema, ProdutoProblema, ProdutoVendido, ItemPedido) => {
      return (QtdeProblema === 0) || ((ProdutoProblema?.Id === ProdutoVendido?.Id) ? (QtdeProblema > ItemPedido?.Quantidade) : false)
    },
    then: (schema) => schema.required("A Quantidade deve ser menor ou igual a quantidade do item do pedido"),
  }),
});


const validationSchemaFinalizacao = Yup.object().shape({
  DescricaoProblema: Yup.string().required("Descrição do problema Obrigatorio para finalizar Assistencia"),
  QtdeProblema: Yup.string().required("Quantidade Obrigatorio para finalizar Assistencia"),
  ValidacaoQuantidade: Yup.string().when(["QtdeProblema", "ProdutoProblema", "ProdutoVendido", "ItemPedido"], {
    is: (QtdeProblema, ProdutoProblema, ProdutoVendido, ItemPedido) => {
      
      return (QtdeProblema === 0) || ((ProdutoProblema?.Id === ProdutoVendido?.Id) ? (QtdeProblema > ItemPedido?.Quantidade) : false)
    },
    then: (schema) => schema.required("A Quantidade deve ser menor ou igual a quantidade do item do pedido"),
  }),

  ProdutoProblema: Yup.object({
    Id: Yup.string().required("Produto Problema Obrigatorio para finalizar Assistencia"),
  }).required("Produto Problema  Obrigatorio para finalizar Assistencia"),
  ProdutoVendido: Yup.object({
    Id: Yup.string().required("Produto Vendido Obrigatorio para finalizar Assistencia"),
  }).required("Produto Vendido  Obrigatorio para finalizar Assistencia"),
  ItemPedido: Yup.object({
    Id: Yup.string().required("Item Pedido Obrigatorio para finalizar Assistencia"),
  }).required("Item Pedido Obrigatorio para finalizar Assistencia"),
  Pedido: Yup.object({
    Id: Yup.string().required("Pedido Obrigatorio para finalizar Assistencia"),
  }).nullable().required("Pedido Obrigatorio para finalizar Assistencia"),
});

export const AssistenciasContext = createContext({});

const AssistenciaProvider = ({ children }) => {
  const [assistencia, setAssistencia] = useState();
  const [assistenciaEdicao, setAssistenciaEdicao] = useState();
  const [assistenciaDuplicada, setAssistenciaDuplicada] = useState();
  const [idAssistenciaDuplicada, setIdAssistenciaDuplicada] = useState();
  const [tabValue, setTab] = useState(0);
  const [menu, setMenu] = useState(false);
  const [modalComentarios, setModalComentarios] = useState(false);
  const [
    modalContentPreviewAnexosComentarios,
    setModalContentPreviewAnexosComentarios,
  ] = useState();
  const [modalPreviewAnexosComentarios, setModalPreviewAnexosComentarios] =
    useState();
  const [listaAnexosComentarios, setListaAnexosComentarios] = useState([]);
  const [modalEditarComentarios, setModalEditarComentarios] = useState();
  const [loading, setLoading] = useState(false);
  const [somenteLeitura, setSomenteLeitura] = useState(false);
  const [erros, setErros] = useState();
  const [validacoes, setValidacoes] = useState();
  const [modalAnexoComentario, setModalAnexoComentario] = useState(false);
  const [modalSelecionarProblema, setModalSelecionarProblema] = useState(false);
  const [responsaveis, setReponsaveis] = useState();
  const [comentarioSelecionado, setComentarioSelecionado] = useState();
  const [loadingAnexos, setLoadingAnexos] = useState();
  const [nextStepCadastroAssistencia, setNextStepCadastroAssistencia] =
    useState(false);
  const [nextStepEditarAssistencia, setNextStepEditarAssistencia] =
    useState(false);
  const [nextStepDuplicarAssistencia, setNextStepDuplicarAssistencia] =
    useState(false);
  const [nextStepVisualizarAssistencia, setNextStepVisualizarAssistencia] =
    useState(false);
  const [anexosTemporarios, setAnexosTemporarios] = useState([]);
  const [obrigarAnexoComentario, setObrigarAnexoComentario] = useState(false);
  const configGeral = GetConfiguracoes();
  const configPermissoesAssistencia = configGeral.ConfiguracaoAssistencia;
  const ExibirCausaAssistencia = configGeral.ConfiguracaoWeb.ExibirCausaAssistencia;
  const params = useParams();
  const constantes = T();

  const navigate = useNavigate();

  async function internalNovaAssistencia(assistencia_base) {
    return validationSchemaGravar
      .validate(assistencia_base, { abortEarly: true })
      .then(() => {
        return AssistenciasService.post(assistencia_base)
      })
  }

  async function internalAtualizarAssistencia(assistencia_base) {
    return validationSchemaGravar
      .validate(assistencia_base, { abortEarly: true })
      .then(async () => {
        return AssistenciasService.put(assistencia_base.Id, assistencia_base)
      })
  }

  async function salvarAssistencia(assistencia_base) {
    const assistencia_Descricao_alterada = {
      ...assistencia_base,
      QtdeProblema: Number(assistencia_base.QtdeProblema),
      DescricaoProblema: assistencia_base.DescricaoProblema?.replace(/(?<!\r)\n/g, '\r\n')
    }

    if (assistencia_Descricao_alterada?.Id > 0) {
      return internalAtualizarAssistencia(assistencia_Descricao_alterada)
    }
    if (params.idorigem) {
      return internalNovaAssistencia(assistencia_Descricao_alterada)
    }
    return internalNovaAssistencia(assistencia_Descricao_alterada)
  }

  async function ValidarAssistencia(assistencia_base) {
    return validationSchema
      .validate(assistencia_base, { abortEarly: true })
  }

  const handleFinalizarAssistencia = async (assistencia_base) => {
    let situationForFinish = {
      Situacao: "Nova"
    }

    validationSchemaFinalizacao.validate(assistencia_base, { abortEarly: true })
      .then((item) => {
        salvarAssistencia(assistencia_base)
          .then((response) => {

            SalvarAnexosTemporarios(response.data?.Id);

            getAssistencia(response.data?.Id);

            AssistenciasService.finalizarAssistencia(response.data.Id, situationForFinish)
              .then(() => {

                toast.success(`Assistência ${response.data.Id} finalizada com sucesso !`);
                navigate("/assistencias")

              })
              .catch((err) => handleError(err))
          })
          .catch((err) => handleError(err))

      })
      .catch((error) => {
        console.log(error.message)
        setErros(error.message)
      })
  }


  const options_toast = {
    onClose: (props) => setErros(null),
  };

  function ObrigarAnexoComentario(assistencia) {
    return configuracaoAnexosAssistencia?.find((item) => {
      return (item.ResponsavelId === assistencia.Responsavel?.Id) && (item.SituacaoAssistencia === assistencia.Situacao)
    })
  }

  async function getAssistencia(id) {
    //setIsLoading(true);
    //setCarregandoAssistencia(true);
    await AssistenciasService.get(Number(id))
      .then((response) => {
        setAssistenciaEdicao(response.data);
        setSomenteLeitura(response.data.Situacao !== "Rascunho");
        setObrigarAnexoComentario(ObrigarAnexoComentario(response.data) ? true : false)
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }

  useEffect(() => {
    setReponsaveis(GetResponsaveis());
  }, []);

  useEffect(() => {
    if (assistenciaEdicao?.Solucao) {
      setNextStepEditarAssistencia(false);
      setNextStepVisualizarAssistencia(true);
      setSomenteLeitura(true);
    }
  }, [assistenciaEdicao?.Solucao]);

  useEffect(() => {
    if (params.id) {
      getAssistencia(params.id);
      setNextStepEditarAssistencia(true);
      setNextStepCadastroAssistencia(false);
    } else if (!params.id) {
      setAssistenciaEdicao(null);
      setNextStepCadastroAssistencia(true);
      setNextStepVisualizarAssistencia(false);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.idorigem) {
      DuplicarAssistencia(params.idorigem);
      setNextStepDuplicarAssistencia(true);
      setNextStepCadastroAssistencia(false);
      setNextStepEditarAssistencia(false);
    } else if (!params.idorigem) {
      setAssistenciaDuplicada(null);
    }
  }, [params.idorigem]);

  useEffect(() => {
    erros && toast.error(`Erro : ${erros}`, options_toast);
  }, [erros]);

  function handleError(error) {
    const _error =
      error.code === "ERR_NETWORK"
        ? constantes.ServerOffLine
        : error.response && error.response.data && error.response.data.error
          ? error.response.data.error.message
          : error.message;
    return setErros(_error);
  }

  function handleLoad() { }

  const abrirMenu = () => {
    setMenu(true);
  };

  const fecharMenu = () => {
    setMenu(false);
  };
  const abrirModalAnexosComentarios = () => {
    setModalPreviewAnexosComentarios(true);
  };
  const fecharModalAnexosComentarios = () => {
    setModalPreviewAnexosComentarios(false);
  };
  const abrirModalComentarios = () => {
    setModalComentarios(true);
  };
  const abrirModalEditarComentarios = () => {
    setModalEditarComentarios(true);
  };
  const fecharModalEditarComentarios = () => {
    setModalEditarComentarios(false);
  };

  const fecharModalComentarios = () => {
    setModalComentarios(false);
  };

  const abrirmodalAnexoComentario = () => {
    setModalAnexoComentario(true);
  };
  const fecharmodalAnexoComentario = () => {
    setModalAnexoComentario(false);
  };

  const abrirModalSelecionarProblema = () => {
    setModalSelecionarProblema(true);
  };
  const fecharModalSelecionarProblema = () => {
    setModalSelecionarProblema(false);
  };

  async function DuplicarAssistencia(id) {
    await AssistenciasService.duplicarAssistenciaService(Number(id))
      .then((response) => {
        setAssistenciaDuplicada(response.data);
        return response.data;
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
      });
  }

  const updateCliente = (cliente, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      Cliente: cliente,
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const updateTipoAssistencia = (tipoAssistencia, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      TipoAssistencia: tipoAssistencia,
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const novoComentario = (comentario, setFieldValue, values) => {
    AssistenciasService.addComentario(values.Assistencia.Id, comentario)
      .then((response) => {
        const assistencia = {
          ...values.Assistencia,
          PrazoResposta: response.data.PrazoResposta,
          Responsavel: response.data.Responsavel,
          Comentarios: [response.data, ...values.Assistencia.Comentarios],
        };

        const assistencia_edicao = {
          ...assistenciaEdicao,
          PrazoResposta: response.data.PrazoResposta,
          Responsavel: response.data.Responsavel,
          Comentarios: [response.data, ...values.Assistencia.Comentarios],
        };

        setFieldValue("Assistencia", assistencia);
        setAssistencia(assistencia);
        setAssistenciaEdicao(assistencia_edicao);
        addAnexosComentarios(
          comentario.AnexosComentarios,
          response.data.Id,
          values.Assistencia.Id
        );
      })

      .catch((error) => console.log(error));
  };

  const getAnexosdoComentario = async (idAssistencia, idComentario) => {
    setLoadingAnexos(true);
    if (idComentario && idAssistencia) {
      await serviceAnexos
        .getAnexosComentarios(idAssistencia, idComentario)
        .then((res) => {
          setListaAnexosComentarios(res.data?.value);
          setLoadingAnexos(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const addAnexosComentarios = async (
    anexoComentario,
    idComentario,
    idAssistencia
  ) => {

    if (idAssistencia && idComentario && anexoComentario) {
      await serviceAnexos
        .postAnexosComentarios(anexoComentario, idAssistencia, idComentario)
        .then((response) => {

        })
        .catch((error) => handleError(error));
    }
  };

  const removerComentario = (comentario, setFieldValue, values) => {
    AssistenciasService.removerComentario(values.Assistencia.Id, comentario.Id)
      .then((response) => {
        const novoscomentarios = values.Assistencia.Comentarios.filter(
          (item) => item.Id !== comentario.Id
        );
        const assistencia = {
          ...values.Assistencia,
          Comentarios: novoscomentarios,
        };
        setFieldValue("Assistencia", assistencia);
        setAssistencia(assistencia);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePedido = (pedido, setFieldValue, values) => {
    const itemPedido = pedido ? values.Assistencia.ItemPedido : { Id: 0 };
    const assistencia = {
      ...values.Assistencia,
      Pedido: pedido,
      ItemPedido: itemPedido
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const updateNota = (nota, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      NotaVenda: nota,
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };


  const updateCausa = (causa, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      CausaAssistencia: causa,
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  function getNota(id) {
    handleLoad(true);
    id &&
      NotaService.get(id)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          handleLoad(false);
        });
  }

  const updateItemPedido = (itempedido, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      ItemPedido: itempedido,
      Pedido: values.Assistencia.Pedido ?? itempedido.Pedido,
      ProdutoVendido: itempedido.Produto
        ? { Id: itempedido.Produto.Id, Nome: itempedido.Produto.Nome, Quantidade: itempedido.Quantidade ?? 0 }
        : { Id: "", Nome: "", Quantidade: 0 },
      // ProdutoProblema: itempedido.Produto
      //   ? { Id: itempedido.Produto.Id, Nome: itempedido.Produto.Nome }
      //   : { Id: "", Nome: "" },
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const updateProdutoVendido = (produtovendido, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      ProdutoVendido: produtovendido,
      QtdeProblema: 0
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const updateProdutoProblema = (produtoproblema, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      ProdutoProblema: produtoproblema,
    };
    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const addComentarios = (comentarios, setFieldValue, values) => {
    const assistencia = {
      ...values.Assistencia,
      Comentarios: [...values.Assistencia.Comentarios, comentarios],
    };

    setFieldValue("Assistencia", assistencia);
    setAssistencia(assistencia);
  };

  const addAnexos = async (data, values, setFieldValue) => {
    if (data) {
      if (values.Assistencia.Id) {

        await serviceAnexos
          .post(data, values.Assistencia.Id)
          .then((res) => {
            setFieldValue("Assistencia.Anexo", res.data);
            toast.success("Anexo adicionado com sucesso");
          })
          .catch((err) => console.log(err));

      } else {

        let id = anexosTemporarios.length;

        data.forEach((item) => {
          const response = {
            Id: id,
            AnexoLink: `/Anexos/${id}`,
            NomeArquivo: item.name,
            TamanhoArquivo: item.size,
            TipoArquivo: item.type,
            AnexoId: id,
            DataHoraInclusao: "2024-07-12T12:21:30.012"
          }
          anexosTemporarios.push({ data: [item], response: response });
          id++;
        })
        const resultados = anexosTemporarios.map((item) => item.response);
        setFieldValue("Assistencia.Anexos", resultados);
        toast.success("Anexo adicionado com sucesso");
      }
    } else {
      toast.error("Falha ao adicionar anexo");
    }
  };

  const getAnexos = async (values, setFieldValue) => {
    if (values.Assistencia.Id) {
      await serviceAnexos
        .getAll(values.Assistencia.Id)
        .then((res) => {
          const data = res.data.value;
          setFieldValue("Assistencia.Anexos", data);
        })
        .catch((err) => console.log(err));

    } else {

      const resultados = anexosTemporarios.map((item) => item.response);
      setFieldValue("Assistencia.Anexos", resultados);

    }
  };

  const deleteAnexo = async (values, Anexo, setFieldValue) => {
    if (values.Assistencia.Id) {

      if (values && Anexo) {
        await serviceAnexos
          .deleteAnexo(values.Assistencia.Id, Anexo.Id)
          .then((response) => {
            getAnexos(values, setFieldValue);
          })
          .catch((error) => {
            handleError(error);
          });
      }

    } else {

      const novosAnexos = anexosTemporarios.filter((item) => item.response.Id !== Anexo.Id);
      const resultados = novosAnexos.map((item) => item.response);
      setAnexosTemporarios(novosAnexos);
      setFieldValue("Assistencia.Anexos", resultados);
    }
  };

  const getPreviewAnexo = async (values) => {

    if (values.Assistencia.Id) {
      const previewAnexo = await serviceAnexos.getAnexo(values);
      return previewAnexo.data;
    } else {


    }

  };

  const formatFilesSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
    return Math.round(bytes / Math.pow(k, i)) + " " + sizes[i];
  };

  const resetFieldsVisitaTecnica = (setFieldValue) => {
    setFieldValue("Assistencia.SituacaoVisitaTecnica", null);
    setFieldValue(
      "Assistencia.InicioDispVisitaTecnica",
      new Date().toISOString().slice(0, 10)
    );
    setFieldValue(
      "Assistencia.FimDispVisitaTecnica",
      new Date().toISOString().slice(0, 10)
    );
    setFieldValue("Assistencia.PrevisaoVisitaTecnica", null);
    setFieldValue("Assistencia.ObservacaoUsuario", "");
  };

  async function SalvarAnexosTemporarios(idAssistencia) {
    anexosTemporarios.forEach((item) => {
      serviceAnexos
        .post(item.data, idAssistencia)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((err) => handleError(err));
    })

  }

  async function SalvarOuAtualizarAssistencia(assistencia, finalizar) {
    salvarAssistencia(assistencia)
      .then((response) => {
        if (assistencia?.Id > 0) {
          toast.success(
            `Assistência ${response.data.Id} atualizada com sucesso !`
          );
          if (finalizar) {
            FinalizarAssistencia
              .then(() => {
                toast.success(`Assistência ${response.data.Id} finalizada com sucesso !`);
                navigate("/assistencias")
              })
              .catch((err) => handleError(err))
          } else {
            navigate("/assistencias");
          }
        } else if (params.idorigem) {
          toast.success(
            `Assistência ${response.data.Id} duplicada com sucesso !`
          );
          getAssistencia(response.data?.Id);
        } else {
          toast.success(`Assistência ${response.data?.Id} criada com sucesso !`);
          SalvarAnexosTemporarios(response.data?.Id);
          getAssistencia(response.data?.Id);

        }
        return response.data;
      })
      .catch((err) => {
        handleError(err);
      });
  }

  const initialValues = {
    Assistencia: {
      Anexos: [],
      Comentarios: [],
      CausaAssistencia: null,
      SolicitarVisitaTecnica: false,
      SituacaoVisitaTecnica: null,
      InicioDispVisitaTecnica: null,
      FimDisVisitaTecnica: null,
      PrevisaoVisitaTecnica: null,
      JustificativaSituacaoVisitaTecnica: null,
      DataEnvio: "",
      DataCadastro: "",
      DataSolicitacao: "",
      ObservacaoRetorno: "",
      ObservacaoUsuario: "",
      DataCriacao: 0,
      Situacao: "",
      Tipo: "",
      DescricaoProblema: "",
      QtdeProblema: 0,
    },
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          SalvarOuAtualizarAssistencia(values.Assistencia, false);
        }}
      >
        <AssistenciasContext.Provider
          value={{
            assistencia,
            assistenciaDuplicada,
            assistenciaEdicao,
            setAssistenciaEdicao,
            constantes,
            tabValue,
            menu,
            loading,
            setTab,
            setLoading,
            setMenu,
            abrirMenu,
            fecharMenu,
            somenteLeitura,
            setSomenteLeitura,
            modalComentarios,
            modalPreviewAnexosComentarios,
            abrirModalAnexosComentarios,
            fecharModalAnexosComentarios,
            abrirModalComentarios,
            fecharModalComentarios,
            fecharModalEditarComentarios,
            abrirModalEditarComentarios,
            modalEditarComentarios,
            abrirmodalAnexoComentario,
            fecharmodalAnexoComentario,
            modalAnexoComentario,
            updateCliente,
            updateTipoAssistencia,
            updatePedido,
            addComentarios,
            updateItemPedido,
            updateProdutoVendido,
            updateProdutoProblema,
            updateNota,
            updateCausa,
            handleError,
            handleLoad,
            getNota,
            addAnexos,
            getAnexos,
            getPreviewAnexo,
            deleteAnexo,
            formatFilesSize,
            responsaveis,
            comentarioSelecionado,
            setComentarioSelecionado,
            novoComentario,
            removerComentario,
            nextStepCadastroAssistencia,
            nextStepEditarAssistencia,
            nextStepDuplicarAssistencia,
            resetFieldsVisitaTecnica,
            nextStepVisualizarAssistencia,
            abrirModalSelecionarProblema,
            fecharModalSelecionarProblema,
            modalSelecionarProblema,
            configPermissoesAssistencia,
            obrigarAnexoComentario,
            ExibirCausaAssistencia,
            modalContentPreviewAnexosComentarios,
            setModalContentPreviewAnexosComentarios,
            addAnexosComentarios,
            getAnexosdoComentario,
            listaAnexosComentarios,
            loadingAnexos,
            handleFinalizarAssistencia,
            ValidarAssistencia
          }}
        >
          {children}
        </AssistenciasContext.Provider>
      </Formik>
    </>
  );
};

export default AssistenciaProvider;