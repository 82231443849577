import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Stack,
} from "react-bootstrap";
import SearchComp from "../../common/searchcomp";
import ListComp2 from "../../common/listcomp2";
import { blockInvalidChar } from "../../utils/blockkeys";
import "../../styles/botoesBuscar.css";

const SearchBarControl = forwardRef((props, ref) => {
  const {
    hideTitle,
    hideId,
    nameId,
    nameDesc,
    placeholder,
    data,
    columns,
    columnsFilter,
    pages,
    records,
    registros,
    disabled,
    typeId,
    size,
    fullscreen,
    disabledDesc,
    disabledId,
    handleChange,
    valueId,
    valueDesc,
    onGetMessage,
    storagename,
    onBlur,
    autoFocus
  } = props;

  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState();
  const [ordertype, setOrderType] = useState("sort");
  const [orderField, setOrderField] = useState(null);

  const [idChanged, setIdChanged] = useState(false);

  function toggle() {
    setModal(!modal);
  }

  function handleButtonClick(event) {
    setModal(!modal);
  }

  function onSearchTitle(searchValue, searchField, serachFilter) {
    let filter = {
      situacao: null,
      campo: searchField,
      operacao: serachFilter,
      valor: searchValue,
      page: 1,
      campoorder: orderField,
      tipoorder: ordertype,
    };
    props.onSearchTitle && props.onSearchTitle(filter);
    setFilter(filter);
  }

  function onItemSelected(item) {
    props.onItemSelected && props.onItemSelected(item);
    toggle();
  }

  function onItemClear() {
    props.onItemClear && props.onItemClear();
  }

  function onGetItem(id) {
    props.onGetItem && props.onGetItem(id, (e) => onValidateModelNotFound(e));
  }

  function onValidateModelNotFound(e) {
    e.code === "ERR_BAD_REQUEST" && e.response && e.response.status === 404
      && onModelNotFound();
  }

  function onModelNotFound() {
    props.onModelNotFound && props.onModelNotFound();
  }

  function handleBlur(e) {
    if (idChanged) {
      if (e.target.value === "") {
        onItemClear()
      } else {
        onGetItem(e.target.value);
      }
      onBlur && onBlur(e);
    }

    setIdChanged(false);
  }

  const handleKeyDown = (e) => {
    blockInvalidChar(e);
    if (e.key === "Enter") {
      onGetItem(e.target.value);
    }
  };

  function onPageSelect(number) {
    let _filter = filter ?? {
      situacao: null,
      campo: null,
      operacao: null,
      valor: null,
      page: number,
      campoorder: orderField,
      tipoorder: ordertype,
    };

    _filter.page = number;
    _filter.campoorder = orderField;
    _filter.tipoorder = ordertype;

    props.onSearchTitle && props.onSearchTitle(_filter);
  }

  function OrderChange(field, type) {
    setOrderField(field);
    setOrderType(type);

    let _filter = {
      ...filter,
      campoorder: field,
      tipoorder: type,
    } ?? {
      situacao: null,
      campo: null,
      operacao: null,
      valor: null,
      page: 1,
      campoorder: orderField || field,
      tipoorder: type || ordertype,
    };

    props.onSearchTitle && props.onSearchTitle(_filter);
  }

  return (
    <>
      {!hideTitle && (
        <Stack>
          <Form.Label className="mb-0">{placeholder}</Form.Label>
        </Stack>
      )}
      <Stack direction="horizontal" gap={1}>
        {!hideId &&
          <Form.Control
            ref={ref}
            name={nameId}
            autoFocus={autoFocus}
            value={valueId}
            onChange={(e) => {
              handleChange(e)
              setIdChanged(true);
            }}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onWheel={(e) => e.target.blur()}
            min="0"
            type={typeId ?? "number"}
            disabled={disabled || disabledId}
            style={{ width: 100 }}
          />
        }
        <Button
          disabled={disabled}
          type="button"
          bsPrefix="ButtonStyle Lupabuscar"
          onClick={() => {
            handleButtonClick();
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        {nameDesc && <div className="vr" />}
        {nameDesc && (
          <Form.Control
            name={nameDesc}
            className="me-auto"
            value={valueDesc}
            onChange={handleChange}
            type="text"
            placeholder={placeholder}
            disabled={disabledDesc ?? true}
          />
        )}
      </Stack>

      {onGetMessage && onGetMessage()}

      <Modal
        size={size ?? "lg"}
        fullscreen={fullscreen ?? false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modal}
        onHide={toggle}
      >
        <ModalHeader toggle={toggle} closeButton></ModalHeader>
        <ModalBody>
          <SearchComp
            storagename={storagename}
            title={placeholder}
            registros={registros}
            campos={columnsFilter}
            activeAutoFocus={true}
            onSearch={(values) => {
              onSearchTitle(
                values.searchValue,
                values.searchField,
                values.searchoperator
              );
            }}
          />
          <ListComp2
            data={data}
            pages={pages}
            records={records}
            columns={columns}
            msgAviso={false}
            selecionar
            onPageSelect={(number) => onPageSelect(number)}
            onItemSelected={(props) => onItemSelected(props)}
            sortname={orderField}
            sorttype={ordertype}
            onSortSelect={(item, sorttype) => {
              if (sorttype === "sort") {
                OrderChange(null, null);
              } else {
                OrderChange(item, sorttype);
              }
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
});

export default SearchBarControl;