
import { Grid, InputLabel, TextField } from "@mui/material";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import CampoSearchListClientes from "../campos_comp/campo-searchlist-clientes";
import { useFormikContext } from "formik";
import DateControl from "../controls_comp/control-Date";
import { getStyle } from "../../consts/situacao_assistencia";
import CamposSearchTipoAssistencia from "../campos_comp/campo-searchlist-tipo-assistencia";

function AssistenciaPrincipal() {
  const {
    constantes,
    somenteLeitura,
    updateCliente,
    updateTipoAssistencia,
    handleLoad,
    handleError,
  } = useContext(AssistenciasContext);

  const formik = useFormikContext();
  const { values, setFieldValue, handleChange } = formik;

  return (
    <>
      <Grid container spacing={2} p={2}>
        {values?.Assistencia?.Id ? (
          <Grid
            spacing={1}
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <InputLabel>{constantes?.Codigo}</InputLabel>
            <TextField
              fullWidth
              size="small"
              placeholder={constantes?.Codigo}
              value={values?.Assistencia?.Id}
              disabled
              name="Assistencia.Id"
            />
          </Grid>
        ) : ""

        }

        {values?.Assistencia?.Situacao &&
          <Grid
            spacing={1}
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <InputLabel>{constantes.Situacao}</InputLabel>
            <Button
              style={getStyle(values?.Assistencia?.Situacao)}
              variant="primary"
              disabled
              className="w-100"
            >
              {values?.Assistencia?.Situacao}
            </Button>
          </Grid>
        }

        {values?.Assistencia?.DataCadastro &&
          <Grid
            item
            flexDirection={"column"}
            xs={12}
            sm={12}
            md={12}
            lg={3}
            display="flex"
          >
            <DateControl
              name="Assistencia.DataCadastro"
              placeholder={constantes?.DataCadastro}
              selected={values?.Assistencia?.DataCadastro}
              disabled={true}
            />
          </Grid>
        }

        {values?.Assistencia?.DataSolicitacao &&
          <Grid
            item
            flexDirection={"column"}
            xs={12}
            sm={12}
            md={12}
            lg={3}
            display="flex"
          >
            <DateControl
              name="Assistencia.DataSolicitacao"
              placeholder={constantes?.DataSolicitacao}
              selected={values?.Assistencia?.DataSolicitacao}
              disabled={true}
            />
          </Grid>
        }

        <Grid

          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection={"column"}
        >
          <CampoSearchListClientes
            constantes={constantes}
            disabled={somenteLeitura || values?.Assistencia?.Pedido?.Id > 0}
            disabledDesc
            nameId="Assistencia.Cliente.Id"
            nameDesc="Assistencia.Cliente.Nome"
            placeholder={constantes?.Cliente}
            onLoad={handleLoad}
            onError={handleError}
            onItemSelected={(item) => {
              const cliente = item ? { Id: item.Id, Nome: item.Nome } : null;
              updateCliente(cliente, setFieldValue, values);

            }}
            onItemClear={() => {

            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection={"column"}
        >
          <InputLabel>{constantes.ObservacaoUsuario}</InputLabel>
          <TextField
            inputProps={{ maxLength: 50 }}
            name="Assistencia.ObservacaoUsuario"
            value={values?.Assistencia?.ObservacaoUsuario}
            onChange={handleChange}
            disabled={somenteLeitura}
            fullWidth
            size="small"
            placeholder={constantes.ObservacaoUsuario} />
        </Grid>

        <Grid

          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection={"column"}
        >
          <CamposSearchTipoAssistencia
            constantes={constantes}
            disabled={somenteLeitura}
            disabledDesc
            nameId="Assistencia.TipoAssistencia.Id"
            nameDesc="Assistencia.TipoAssistencia.Nome"
            placeholder={constantes?.TipoAssistencia}
            onLoad={handleLoad}
            onError={handleError}
            onItemSelected={(item) => {
              const tipo = item ? { Id: item.Id, Nome: item.Nome } : null;
              updateTipoAssistencia(tipo, setFieldValue, values);

            }}
            onItemClear={() => {

            }}
          />
        </Grid>

        {/* <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection={"column"}
        >
          <InputLabel>{constantes.Observacoes}</InputLabel>
          <TextField
            name="Assistencia.Observacoes"
            value={values?.Assistencia?.Observacoes}
            onChange={handleChange}
            disabled={somenteLeitura}
            fullWidth
            size="small"
            placeholder={constantes.Observacoes} />
        </Grid> */}

      </Grid >
    </>
  );
}

export default AssistenciaPrincipal;
